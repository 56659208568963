/* Colors */
:root {
    --accent-color: #b3c801;
}

body {
    background-color: #f8f8f8;
    background-image: url("./images/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

p, h1, h2, h3, h4, h5, h6 {
    color: #393a39;
}

p {
    margin-bottom: 4px;
}

.page-title {
    padding-left: 25px;
}

.error {
    color: #d50000;
}

.warning {
    color: goldenrod;
}

.success {
    color: limegreen;
}

table {
    --bs-table-bg: transparent !important;
}

/* not needed for mobile tables */
@media (min-width: 767px) {
    tbody>tr:hover:not(.worked-hours-table>tbody>tr):not(.unhoverable) {
        cursor: pointer;
    }

    td:not(.worked-hours-table>tbody>tr>td), th:not(.worked-hours-table>thead>tr>th) {
        white-space: pre-line;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.5em;
        max-height: 3em;    /* max-height double the line-height, so only two lines shown before wrapping text */
        width: fit-content;
        max-width: 8vw;
    }
}

.table-selected-row {
    background-color: #cccccc;
}

.form-floating {
    overflow: hidden;
}

.form-floating>label {
    color: #595959;
}

.form-control:disabled {
    background-color: rgba(204, 204, 204, 0.3);
}

.form-floating>.form-control:disabled~label::after,
.form-floating>:disabled~label::after {
    background-color: transparent;
}

.hoverable {
    cursor: pointer;
}

/* Glossy classes */

.glossy-background {
    margin: 10px;
    padding: 20px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 3px solid rgba(255, 255, 255, 0.3);
}

.glossy-background.smaller {
    padding: 5px;
}

.glossy-error-background {
    margin: 10px;
    padding: 20px;
    /* From https://css.glass */
    background: rgba(213, 0, 0, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(213, 0, 0, 0.3);
}

.modal-content {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.75);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 3px solid rgba(255, 255, 255, 0.3);
}