/* Extra hours */
.company-worked-hours-table > tbody > tr > td > div:nth-child(3) {
    color: #e11212;
}

/* Holiday hours */
.company-worked-hours-table > tbody > tr > td > div:nth-child(4) {
    color: goldenrod;
}

/* Sick hours */
.company-worked-hours-table > tbody > tr > td > div:nth-child(5) {
    color: dodgerblue;
}

/* Donation hours */
.company-worked-hours-table > tbody > tr > td > div:nth-child(6) {
    color: darkred;
}

/* Furlough hours */
.company-worked-hours-table > tbody > tr > td > div:nth-child(7) {
    color: #336600;
}

/* Travel hours */
.company-worked-hours-table > tbody > tr > td > div:nth-child(8) {
    color: steelblue;
}

/* Expenses */
.company-worked-hours-table > tbody > tr > td > div:nth-child(9) {
    color: rebeccapurple;
}

.clickable:hover {
    cursor: pointer;
    color: var(--accent-color);
}