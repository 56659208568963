.glossy-button {
    padding: 10px 30px;
    color: rgba(57, 58, 57, 0.8);
    /* From https://css.glass */
    background: rgba(165, 165, 165, 0.33);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 2px solid rgba(165, 165, 165, 0.6);
}

.glossy-button:hover,
.glossy-button:active,
.glossy-button:focus-visible {
    color: #393a39 !important;
    /* From https://css.glass */
    background: rgba(165, 165, 165, 0.33) !important;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 2px solid rgba(89, 89, 89, 0.6) !important;
}

.light-glossy-button {
    color: #595959;
    max-height: min-content;
    /* From https://css.glass */
    background: rgba(165, 165, 165, 0.15);
    border-radius: 10px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(89, 89, 89, 0.6);
}

.light-glossy-button:hover,
.light-glossy-button:active,
.light-glossy-button:focus-visible,
.light-glossy-button:active:focus {
    color: #393a39 !important;
    /* From https://css.glass */
    background: rgba(165, 165, 165, 0.33) !important;
    border: 1px solid rgba(89, 89, 89, 0.6) !important;
}

.text-button {
    color: #595959;
    max-height: min-content;
    background: transparent;
    border: 0;
}

.text-button:hover,
.text-button:active,
.text-button:focus-visible,
.text-button:active:focus {
    color: #393a39 !important;
    background: transparent !important;
}

.single-line-button {
    white-space: nowrap;
}