.orders-page-first-column {
    transition: all .25s ease;
}

.orders-page-second-column {
    opacity: 0;
    animation: appear-animation .25s ease .125s 1 normal forwards;
}

.orders-table td {
    vertical-align: middle;
}

th.comparable {
    cursor: pointer;
}

.cleared > td {
    color: forestgreen;
}

.partially-cleared > td {
    color: goldenrod;
}

.expired > td {
    color: #d50000;
}

.cancelled > td {
    color: rebeccapurple;
}

@keyframes appear-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.order-pane {
    position: sticky;
    top: 115px;
}

.clickable-arrow {
    color: black;
}

.clickable-arrow:hover {
    cursor: pointer;
}

.unclickable-arrow {
    color: lightgray;
}