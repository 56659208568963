.navbar {
    height: 84px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    box-shadow: 0 10px 25px rgba(153, 153, 153, 0.6);
}

.navbar-padding {
    padding-left: 1rem;
    padding-right: 1rem;
}

.navbar-row {
    width: 100vw;
}

.text {
    padding-left: 20px;
    font-size: 130%;
}

.brand-image {
    max-width: 200px;
    max-height: 60px;
}

.brand-image:hover {
    cursor: pointer;
}

.offcanvas a:not(.offcanvas-footer a) {
    color: #393a39;
    font-size: x-large;
    text-decoration: none;
}

/* This selects the icons next to the links. */
.offcanvas svg {
    color: #393a39;
    font-size: xx-large;
    margin-right: 16px;
}

.offcanvas-footer {
    position: absolute;
    left: 0;
    bottom: 10px;
}

.offcanvas-footer p,
.offcanvas-footer a {
    color: #808080;
    font-size: small;
    text-decoration: none;
}

.offcanvas-footer:hover {
    color: #393a39;
}