.padded-form-input {
    margin-top: 10px;
    margin-bottom: 10px;
}

.signup-logo {
    margin-top: 20px;
    width: auto;
    height: 10vh;
}

.username-field {
    margin-bottom: 4px;
}

.input-group-text {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
    border: 1px solid var(--bs-border-color);
    border-right-width: 0;
}

.form-control {
    border-radius: 12px;
    border-width: 1px;
}

.successful-signup-container {
    height: 100vh;
}